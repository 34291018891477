

    import { defineComponent, ref } from 'vue'
    import Button from '../components/Button.vue'
    import Container from '../components/Container.vue'
    import FileUpload from '../components/Form/FileUpload.vue'
    import CheckboxInput from '../components/Form/CheckboxInput.vue'
    import Input from '../components/Form/Input.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Checkmark from '../components/Icons/Checkmark.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Paragraph from '../components/Paragraph.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { useValidation } from '../compositions/form/UseValidation'
    import { email, required } from '../compositions/form/UseValidators'
    import { useApi } from '../compositions/UseApi'
    import { currentActiveBreakPoint } from '../compositions/UseResponsiveValue'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Breakpoint } from '../enums/Breakpoint'
    import { Theme } from '../enums/Theme'
    import { RoleType } from '../enums/RoleType'
    import { InputType } from '../enums/InputType'
    import { JobEnquiryInterface } from '../Interfaces/EnquiryInterface'
    import { useTheme } from '../compositions/UseTheme'
    import MetaInfo from '../components/MetaInfo.vue'

    export default defineComponent({
        name: 'WorkWithUs',
        components: {
            MetaInfo,
            Container,
            PageHeader,
            Grid,
            Column,
            Input,
            Paragraph,
            Button,
            CheckboxInput,
            FileUpload,
            Checkmark,
            ThemeChanger
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { contact } = useTranslation()
            const lang = contact.value
            const formSubmitted = ref(false)
            const formLoading = ref(false)

            let resume = null as File | null

            const { validation, validate, getData } = useValidation({
                name: [ required(lang.name_required) ],
                email: [ required(lang.email_required), email(lang.email_valid) ],
                githubUsername: [],
                yourDetails: []
            })

            const selectedRole = ref(RoleType.VueJs)

            return {
                lang: contact,
                currentActiveBreakPoint,
                Breakpoint,
                Theme,
                selectedRole,
                RoleType,
                formSubmitted,
                formLoading,
                InputType,
                validation,
                setResume(file: File) {

                    resume = file

                },
                async submitForm() {

                    if (!validate()) {

                        return

                    }

                    formLoading.value = true

                    const data = {
                        ...getData(),
                        role: selectedRole.value
                    } as JobEnquiryInterface

                    if (resume) {

                        data.resume = resume

                    }

                    const response = await useApi().jobEnquiry(data as JobEnquiryInterface)

                    if (response.isErr()) {

                        alert(response.error.message)
                        formLoading.value = false

                    }

                    if (response.isOk()) {

                        formSubmitted.value = true

                    }

                    formLoading.value = false

                }
            }

        }
    })

